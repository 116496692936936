export default {
  blogerName: 'PAOLLA',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@paollazoz',
    },
    {
      name: 'youtube',
      url: 'https://youtube.com/@paahjoga?si=rUQCvcp0_EbfF3pT',
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/paollazoz/?hl=pt-br',
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://monrocasino.life/c9d701626',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/c17978fae',
      gameTitle: 'Jet Air',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/ceff0b662',
      gameTitle: 'Doors of Sol',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/cd965f310',
      gameTitle: 'Doors Of Fresh',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/ce505e308',
      gameTitle: 'IZZI ART',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c1321436f',
      gameTitle: 'LEGZO PUNK',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c4bae09df',
      gameTitle: 'STARDA QUEEN',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/ca077a37a',
      gameTitle: 'Alien Fruits ',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>PAOLLA</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'PAOLLA',
  casinoBonus1: '<strong>100% + 500FS</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>para registro con código promocional</div>',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
  attentionText: ''
}
